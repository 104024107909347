import React from "react";
import { Routes, Route } from "react-router-dom";
import CRMWaitlist from "./crm/CrmWaitList";
import ProtectedRoute from "./crm/ProtectedRoute";
import VideoPlayerPage from "./pages/VideoPlayerPage";
import VideoPlayerPageUsers from "./pages/VideoPlayerPageUsers";
//import WeAreHiring from "./pages/WeAreHiring";
import NotFoundPage from "./components/NotFoundPage";
import usePageTracking from "./hooks/usePageTracking";
//import DocumentationHome from "./pages/help/DocumentationHome";
import DocumentationGDPR from "./pages/help/DocumentationGDPR";
import Layout from "./components/Layout";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
//const Bioteam = React.lazy(() => import("./pages/Bioteam2024x"));
const ProductPage = React.lazy(() => import("./pages/SlideLibrary"));
const DocumentationPage = React.lazy(
  () => import("./pages/help/DocumentationHome")
);

interface AppRoutesProps {
  launched: boolean;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ launched }) => {
  usePageTracking();

  return (
    <Layout launched={launched}>
      <Routes>
        <Route path="/" element={<LandingPage launched={launched} />} />
        <Route path="/login365" element={<LoginPage />} />
        <Route
          path="/crm-waitlist"
          element={
            <ProtectedRoute>
              <CRMWaitlist />
            </ProtectedRoute>
          }
        />
        <Route path="/video-player" element={<VideoPlayerPage />} />
        <Route path="/ic" element={<DocumentationPage />} />
        <Route path="/privacy" element={<DocumentationGDPR />} />
        <Route path="/video-player-users" element={<VideoPlayerPageUsers />} />
        <Route
          path="/Slidelibrary"
          element={<ProductPage launched={false} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
