// src/components/Footer.tsx
import React from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  useMediaQuery,
  useTheme,
  Stack,
  Divider,
} from "@mui/material";

interface FooterProps {
  launched: boolean;
  onOpenNotice: () => void;
}

const Footer: React.FC<FooterProps> = ({ launched, onOpenNotice }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Divider sx={{ bgcolor: "#1c1d1d", width: "100%" }} />
      <Box
        sx={{
          //bgcolor: theme.palette.background.paper,

          //background: `linear-gradient(180deg, ${"#0a0b0c"} 0%, ${"#0d1117"} 60%,${"#0d1117"} 70%, ${"#0a0b0c"} 100%)`,
          background: "#0a0b0c",
          color: theme.palette.text.secondary,
          //padding: isMobile ? "9px 0px 23px 23px" : "23px 23px 23px 33px",

          display: "flex",
          justifyContent: "center", // Center the content
          paddingBottom: "30px", // Prevent double padding
        }}
      >
        <Grid
          container
          spacing={0}
          alignItems="flex-start"
          sx={{ maxWidth: 1700, width: "100%", margin: "0 auto", padding: 3 }}
          //justifyContent="space-between"
        >
          {launched && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ marginTop: "10px", mb: "50px" }}>
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        mr: "3px",
                      }}
                    >
                      <img
                        src="/icon-256x256.png"
                        alt="logo"
                        style={{ height: "25px" }}
                      />
                    </Box>{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Stack direction="column" spacing={0}>
                  <Typography
                    variant={isMobile ? "caption" : "subtitle2"}
                    color="white"
                  >
                    SoftCap AS
                  </Typography>
                  <Typography variant={isMobile ? "caption" : "subtitle2"}>
                    Sykehusvegen 23, 9019 Tromsø, Norway{" "}
                  </Typography>

                  <Typography
                    variant={isMobile ? "caption" : "subtitle2"}
                    sx={{ ml: 0.0, mt: isMobile ? 0.6 : 1.2 }}
                  >
                    <Link
                      href="/privacy"
                      color="inherit"
                      onClick={onOpenNotice}
                    >
                      Privacy policy
                    </Link>
                  </Typography>
                </Stack>
              </Grid>

              {/*
              <Grid item xs={4} sm={4} md={4}>
                <Stack direction="row" spacing={6}>
                  <Box sx={{ mt: "0px", alignContent: "right" }}>
                    <Link
                      //href="https://en.innovasjonnorge.no/"
                      target="_blank"
                      rel="noopener noreferrer"
                      align="right"
                    >
                      <img
                        src="innonor.png"
                        alt="Partner Logo 1"
                        style={{
                          marginTop: "0px",
                          maxHeight: isMobile ? "30px" : "36px",
                          height: "auto",

                          //filter: "grayscale(1) invert(1) contrast(0.6)",
                        }}
                      />
                    </Link>
                  </Box>
                </Stack>
              </Grid>
 */}
            </>
          )}

          {/* Additional columns as needed */}
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
