import React from "react";
import { Box, Typography, Link } from "@mui/material";

const DocumentationGDPR: React.FC = () => {
  return (
    <Box sx={{ fontFamily: "Arial, sans-serif", m: 3, color: "text.primary" }}>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        SoftCap AS GDPR Compliance Statement for DealMaker
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        Last Updated: January 17, 2025
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Who We Are
      </Typography>
      <Typography paragraph>
        <strong>Data Processor</strong>: SoftCap AS, Sykehusvegen 23, 9019
        Tromsø, Norway. Norwegian Org. No. 931 706 004
      </Typography>
      <Typography paragraph>
        <strong>Contact</strong>:{" "}
        <Link href="mailto:contact@softcap.no" target="_blank">
          contact@softcap.no
        </Link>{" "}
        | Phone: Available 24/7 for customers.
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Data We Process
      </Typography>
      <Typography paragraph>
        DealMaker processes the following personal data on behalf of our
        customers (data controllers):
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>
          <Typography>
            <strong>User Profiles</strong>: Names, email addresses, and
            organizational details via Microsoft 365 SSO.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Usage Statistics</strong>: In-product data (e.g., search
            queries, slide suggestion interactions) to improve features like
            search and recommendations.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Slide Content/Metadata</strong>: Uploaded slides (.pptx,
            .png) and searchable text, stored securely in Google Cloud.
          </Typography>
        </li>
      </Box>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Lawful Basis
      </Typography>
      <Typography paragraph>
        <strong>Contract</strong>: Processing user profiles and slide data is
        necessary to deliver DealMaker’s core functionality to your
        organization.
      </Typography>
      <Typography paragraph>
        <strong>Legitimate Interest</strong>: Basic usage statistics are
        processed to enhance product features, anonymized where possible,
        without overriding user rights.
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        How We Protect Your Data
      </Typography>
      <Typography paragraph>
        <strong>Encryption</strong>: Data is encrypted in transit (TLS) and at
        rest using Google Cloud’s standards.
      </Typography>
      <Typography paragraph>
        <strong>Tenant Isolation</strong>: Each organization’s data is
        segregated, with optional dedicated storage.
      </Typography>
      <Typography paragraph>
        <strong>Security Rules</strong>: Declarative access controls in Google
        Cloud ensure only authorized users access data.
      </Typography>
      <Typography paragraph>
        <strong>Breach Response</strong>: We maintain procedures to notify your
        organization and relevant authorities within 72 hours of a significant
        breach, per GDPR Article 33.
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Third-Party Processors
      </Typography>
      <Typography paragraph>We rely on:</Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>
          <Typography>
            <strong>Google Cloud</strong> (Firestore, Storage, Cloud Run): Data
            is stored in EU regions (e.g., Finland for Nordic customers) under a
            GDPR-compliant Data Processing Agreement.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Microsoft 365</strong> (Graph API): Authentication and
            profile data are managed via your organization’s Microsoft 365
            tenant.
          </Typography>
        </li>
      </Box>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Your Rights
      </Typography>
      <Typography paragraph>
        As DealMaker serves employees of our corporate customers, rights (e.g.,
        access, rectification, erasure, portability) are exercised through your
        organization’s Microsoft 365 administrator. Contact your IT admin to
        request data actions. SoftCap AS will assist your organization as
        needed.
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Data Retention
      </Typography>
      <Typography paragraph>
        <strong>User Data</strong>: Retained while your organization’s
        subscription is active, deleted upon termination per your instructions.
      </Typography>
      <Typography paragraph>
        <strong>Slide Data</strong>: Stored until removed by your organization’s
        admin or subscription ends.
      </Typography>
      <Typography paragraph>
        <strong>Usage Stats</strong>: Aggregated and anonymized; retained for
        product improvement, with no individual identifiers linked after 12
        months.
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Compliance Assurance
      </Typography>
      <Typography paragraph>
        <strong>Data Minimization</strong>: We collect only what’s essential for
        DealMaker’s operation.
      </Typography>
      <Typography paragraph>
        <strong>Design</strong>: Privacy is built into our architecture,
        leveraging Google Cloud’s SOC 2 compliance.
      </Typography>
      <Typography paragraph>
        <strong>Audit</strong>: SOC 2 certification is in progress.
      </Typography>

      <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
        Contact & Inquiries
      </Typography>
      <Typography paragraph>
        For questions or to exercise your rights through your organization,
        contact us at{" "}
        <Link href="mailto:contact@softcap.no" target="_blank">
          contact@softcap.no
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default DocumentationGDPR;
