import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import GDPRNotice from "./GDRPNotice";

interface LayoutProps {
  children: React.ReactNode;
  launched: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, launched }) => {
  const location = useLocation(); // Now we can use useLocation() safely

  // Define routes where Footer should not be displayed
  const hiddenFooterRoutes = ["/ic"];

  const [showNotice, setShowNotice] = React.useState(
    localStorage.getItem("gdprNoticeSeen") !== "true"
  );

  const handleOpenNotice = () => {
    setShowNotice(true);
  };

  const handleCloseNotice = () => {
    localStorage.setItem("gdprNoticeSeen", "true");
    setShowNotice(false);
  };

  return (
    <>
      {children}
      {showNotice && <GDPRNotice onClose={handleCloseNotice} />}
      {launched && !hiddenFooterRoutes.includes(location.pathname) && (
        <Footer launched={launched} onOpenNotice={handleOpenNotice} />
      )}
    </>
  );
};

export default Layout;
