import React, { Suspense, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import AppRoutes from "./AppRoutes";

const Analytics = React.lazy(() => import("./components/Analytics"));

const App: React.FC = () => {
  const [launch, setLaunch] = useState(true);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense>
          <Analytics />
        </Suspense>
        <Suspense>
          <AppRoutes launched={launch} />
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default App;
